<template>
  <div>
    <b-col v-if="role === 'admin' || role === 'manager'">
      <b-alert
        v-if="errorMessage != null"
        show
        variant="danger"
        dismissible
        fade
        @dismissed="clearError"
      >
        <b-icon
          icon="exclamation-triangle-fill"
          scale="2"
          variant="danger"
        />
        {{ errorMessage }}
      </b-alert>
      <b-form-group
        label="Veuillez saisir le BL du colis"
        label-for="BL"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="id"
            placeholder="BL"
            type="number"
          />
          <b-button
            :disabled="id === null"
            @click.prevent="checkOrderById"
          >
            <b-icon
              icon="search"
              aria-hidden="true"
            />
            Chercher colis
          </b-button>
        </b-input-group>
      </b-form-group>
      <b-col class="mt-2">
        <b-overlay
          v-if="!order"
          show
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-card
            title="En Attente d'un scan..."
            aria-hidden="true"
          >
            <b-card-text>
              Scanner un colis pour modifier le status
            </b-card-text>
          </b-card>
        </b-overlay>
        <b-card
          v-if="order != null"
          class="my-5"
        >
          <b-card-header>
            Modification rapide du status du colis
          </b-card-header>
          <b-card-body>
            <div class="d-flex justify-content-center mb-3">
              <barcode :value="order.id.toString()" />
            </div>
            <p><strong>Client:</strong> {{ order.name }}</p>
            <p><strong>Adresse:</strong> {{ order.address }}</p>
            <p><strong>Telephone:</strong> {{ order.phone1 }}</p>
            <p><strong>Prix:</strong> {{ order.price_ttc }}</p>
            <p><strong>Status:</strong> {{ statusText }}</p>
            <p><strong>Date:</strong> {{ order.date }}</p>
            <p><strong>Gouvernorat:</strong> {{ order.governorate }}</p>
            <p><strong>Delegation:</strong> {{ order.delegation }}</p>
            <v-select
              v-model="selectedStatus"
              :options="statusOptions"
              label="name"
              :reduce="status => status.id"
            />
            <b-button
              class="mt-1"
              variant="primary"
              @click="updateStatus"
            >
              <b-icon icon="save" />
              Update Status
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-col>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import VueBarcode from 'vue-barcode'
import store from '@/store/store'

export default {
  name: 'QuickUpdateOrder',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    // BFormSelect,
    // flatPickr,
    // BPagination,
    // BTooltip,
    BCard,
    BCol,
    vSelect,
    barcode: VueBarcode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      phone: null,
      price_ttc: null,
      order: null,
      statusOptions: [
        { id: 2, name: 'Colis au dépôt' },
        { id: 3, name: 'En cours' },
        { id: 4, name: 'Livré' },
        { id: 5, name: 'Reportée' },
        { id: 7, name: 'Retour au fournisseur' },
        { id: 10, name: 'Retour Inter agence' },
        { id: 11, name: 'Retour Inter agence Recu' },
      ],
      selectedStatus: null,
      isLoading: false,
      errorMessage: null,
      role: '',
      id: null,
    }
  },
  computed: {
    statusText() {
      switch (this.order.status) {
        case 2:
          return 'Colis au dépôt'
        case 3:
          return 'Colis en cours'
        case 4:
          return 'Livré'
        case 5:
          return 'Colis reporté'
        case 7:
          return 'Retour au fournisseur'
        case 10:
          return 'Retour Inter agence'
        case 11:
          return 'Retour Inter agence Recu'
        default:
          return ''
      }
    },
  },
  created() {
    this.role = store.state.user.role
  },
  methods: {
    reset() {
      this.order = null
      this.phone = null
      this.price_ttc = null
      this.selectedStatus = null
    },
    async checkOrder() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/orders/ready-for/quick-update', {
          params: {
            phone: this.phone,
            price_ttc: this.price_ttc,
          },
        })
        this.order = data
        if (this.order != null) {
          this.errorMessage = null
        }
        this.selectedStatus = data.status
        this.price_ttc = null
        this.phone = null
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        const [errorMessage] = error.response.data
        this.errorMessage = errorMessage
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async checkOrderById() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/orders/ready-for/quick-update/search-by-id/', {
          params: {
            order_id: this.id,
          },
        })
        this.order = data
        if (this.order != null) {
          this.errorMessage = null
        }
        this.selectedStatus = data.status
        this.id = null
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        const [errorMessage] = error.response.data
        this.errorMessage = errorMessage
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async updateStatus() {
      const payload = {
        status: this.selectedStatus,
      }
      try {
        this.isLoading = true
        await axios.put(`/api/orders/quick-update/${this.order.id}/`, payload)
        this.showToast('success', 'top-center', 'Le statut du colis a été mis a jours avec success')
        this.reset()
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    clearError() {
      this.errorMessage = null
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
  <style lang="scss" >
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  // CSS
  .custom-danger {
    background-color: #ff5252;
    color: #fff;
  }

  .alert-icon {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: 1;
  }
  </style>
